/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/global.css"

/*https://github.com/gatsbyjs/gatsby/issues/17676
This fixes the issue of the index page server side div class indexContainer being renamed to indexHeader
*/

import ReactDOM from "react-dom"

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
